import React from "react";

const Demo  = ({ className, name, projectId, ...rest }) => {

  return (
    <>
    <div className={className} {...rest} style={{position: "relative", top:"1px", zIndex:"100"}}> 
    <div className="container mb-0 pb-20">
    <div className="row">
      <div className="col-12 text-center">
        <h3 className="mb-0 ">Demo</h3>
        <p className="mb-10">{name}</p>
        <div
          className="rounded-8"
          data-aos="fade-up"
          data-aos-duration={800}
          data-aos-delay={500}
        >
            <div
              id="digma-embed"
              className="digma"
              data-projectid={projectId}
            ></div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </>
  );
};

export default Demo;
