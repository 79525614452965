import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/shoppable/Hero";
import Main from "../../../sections/shoppable/Main";
import Features from "../../../sections/common/Features";
import VideoDemo from "../../../sections/common/VideoDemo";
import Wave from "../../../sections/common/Wave";
import WaveReverse from "../../../sections/common/WaveReverse";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from "../../../assets/image/metaimg/shoppable.jpg";

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Interactive Shoppable Video Player and Platform"
          description="Got products to sell on your video? Showcase your products with relevant information such as price, size, or type."
          image={metaImg}
        />   
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.1.1.0.css?v=shoppable'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.1.1.0.js?v=shoppable'}></script>
        </Helmet>                             
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/ecommerce">E-commerce</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/ecommerce/shoppable">Shoppable</Link>
              </li>                            
              </ol>
           </nav>
        </div>             
        <Hero />
        <WaveReverse pos="top" color="#0c426d" background="#fff" /> 
        <VideoDemo className="bg-digma-dark" name="Cosmic Crystals integrated Shopify" projectId="99985de4-f99b-4b6a-8ef2-eefd21f912ca" />
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d" /> 
        <Main className="grey" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
