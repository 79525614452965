import React, { useContext } from "react";

import GlobalContext from "../../context/GlobalContext";
import imgC from "../../assets/image/hero/shoppable.jpg";

const CTA  = ({ className, ...rest }) => {
  const gContext = useContext(GlobalContext);

  return (
    <div className={className} {...rest} style={{position: "relative", top:"-2px", zIndex:"100"}}>
      <div className="pt-14 pt-md-19 pt-lg-10 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <div
              className="bg-images d-flex align-items-center justify-content-center max-w-540 py-18 py-sm-28 rounded-10 mx-auto"
              css={`
                background-image: url(${imgC});
              `}
            >
              <a
                href="/#"
                className="video-btn sonar bg-blue-3-op5 text-white circle-98 font-size-8"
                onClick={(e) => {
                  e.preventDefault();
                  gContext.toggleVideoModal();
                }}
                style={{visibility:"hidden"}}
              >
                <i className="fa fa-play" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >Let DIGMA help make your video content stand out from the competition. Captivate your online audience with our interactive and engrossing overlays that can be completely tailored to each unique campaign and its demographic. 
              </p>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>Increase your online performance, expand your web presence, and boost your advertising returns. Using the most up to date advanced technology, DIGMA is an indispensable and instantly effective tool for marketing, outreach and online retail. </p>
              <div>
                <p className="font-size-3 line-height-20 mb-2 mr-xs-17 mr-md-11 mr-lg-18 mr-xl-20" data-aos="fade-up"
                data-aos-duration={900}>
                “Amazing that you can integrate shopify with my videos. Really helped my campaign”
                </p>
                <h6 className="font-size-3 font-weight-medium" data-aos="fade-up"
                data-aos-duration={900}>
                Violet Stupple, CEO of Cosmic Crystals
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CTA;
